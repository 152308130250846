<template>
    <div ref="hoverElement" style="background-color: cornsilk!important;max-width: 600px;text-wrap: pretty;" 
    class="popover-personinfo bs-popover-auto fade shadow" role="tooltip">
        <div v-if="isLoaded" class="popover-header p-2" style="background-color: cornsilk!important;">
            <div class="fw-bold d-flex ">
                <small>{{objectInfo.Name}} (ID: {{objectInfo.ID}})</small>
                <div class="me-2 ms-auto" style="cursor: default;">
                    <a :href="`/object-register/objectdetails?ID=${objectInfo.ID}`"><i class="bi bi-box-arrow-up-right"></i></a>
                </div>
            </div>
        </div>
        <h3 v-else class="popover-header p-2">
            {{$t('Loading...')}}
        </h3>
        <div class="popover-body p-2">
            <template v-if="isLoaded">
                <div class="row gx-5" style="font-size:small">
                    <div class="col-12 mb-0">
                        <span style="font-weight: 500;">{{ $t("Object Type") }}:</span>&nbsp;<span>{{ objectInfo.ObjectType }}</span>
                    </div>
                    <div class="col-12 mb-0" >
                        <span style="font-weight: 500;">{{ $t("Name / Tag.No") }}:</span>&nbsp;<span>{{ objectInfo.Name }}</span>
                    </div>
                    <div class="col-12 mb-0" >
                        <span style="font-weight: 500;">{{ $t("Description") }}:</span>&nbsp;<span>{{ objectInfo.Description }}</span>
                    </div>
                    <div class="col-12 mb-0" >
                        <span style="font-weight: 500;">{{ $t("Org Unit") }}:</span>&nbsp;<span>{{ objectInfo.OrgUnit }}</span>
                    </div>
                </div>
            </template>
            <template v-else>
                {{$t('Loading...')}}
            </template>
        </div>
    </div>
</template>


<script lang="ts">

const ObjectCache = new Map<number, IobjectInfo>();

export interface IobjectInfo {
    ID: string;
    Name: string;
    OrgUnit: string;
    ObjectType: string;
    Description: string;
};

export interface IObjectHoverProps {
    objectId?: number;
}
</script>


<script setup lang="ts">
import { API } from 'o365-modules';
import { ref, watch, onMounted } from 'vue';

const props = defineProps<IObjectHoverProps>();

const showContent = ref(false);
const isLoaded = ref(false);
const objectInfo = ref<IobjectInfo | null>(null);
const hoverElement = ref<HTMLElement | null>(null);

function hoverShow() {
    showContent.value = true;
    if (isLoaded.value && objectInfo.value?.ID != `${props.objectId}`) {
        handleShow();
    }
}

function hoverHide() {
    showContent.value = false;
}

defineExpose({hoverElement, hoverHide, hoverShow});

async function loadObjectData(objectId: number) {
    const result = await API.request({
        requestInfo: '/nt/api/data/aviw_Assets_Objects',
        method: 'POST',
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
            "viewName": "aviw_Assets_Objects",
            "distinctRows": false,
            "skip": 0,
            "fields": [
                { name: "ID", type: "number" },
                { name: "Name", type: "string" },
                { name: "OrgUnit", type: "string" },
                { name: "Description", type: "string" },
                { name: "ObjectType", type: "string" },
                { name: "Created", type: "date" },
            ],
            "maxRecords": 1,
            "whereClause": `ID = ${objectId}`,
            "masterDetailString": null,
            "filterString": null,
            "operation": "retrieve"
        })
    });
    return result != null ? result[0] : result;
}

async function handleShow() {
    isLoaded.value = false;
    objectInfo.value = null;
    if (props.objectId == null) { return; }
    let objectData = ObjectCache.get(props.objectId);
    if (objectData == null) {
        try {
            objectData = await loadObjectData(props.objectId);
            if (objectData == null) { return; }
            ObjectCache.set(props.objectId, objectData);
        } catch (ex) {
            console.error(ex);
            return;
        }
    }
    objectInfo.value = objectData;
    isLoaded.value = true;
}

watch(() => props.objectId, (_newObjectId) => {
    handleShow();
});

onMounted(() => {
    handleShow();
});

</script>
